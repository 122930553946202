"use client";

import { classNames } from "@local/utils/src/classNames";
import { Stars, type StarsProps } from "./Stars";
import { IS_BAERSKIN, IS_HAM } from "@/lib/const";
import { TrustpilotMicroCombo } from "../TruspilotWidgets";
export enum verticalAlignStars {
  center = "center",
  right = "right",
}
interface StarsWithReviewsProps extends StarsProps {
  displayRating?: boolean;
  reviews?: number;
  dark?: boolean;
  vertical?: verticalAlignStars | boolean;
  overrideColor?: string;
  longDescription?: boolean;
}
export const StarsWithReviews = ({
  rating,
  reviews,
  className,
  dark,
  vertical,
  overrideColor,
  longDescription
}: StarsWithReviewsProps) => <div className={classNames("flex flex-row justify-start", "flex flex-row align-middle", rating % 1 ? "space-x-0" : "space-x-2", className ?? "", vertical ? "flex-col" : "flex-row", vertical && vertical === verticalAlignStars.center ? "items-center" : "", vertical && vertical === verticalAlignStars.right ? "items-end" : "items-center")} data-sentry-component="StarsWithReviews" data-sentry-source-file="StarsWithReviews.tsx">
    {IS_BAERSKIN || IS_HAM ? <TrustpilotMicroCombo dark={dark} tall cssClass="!mt-2 -mb-1 md:mb-1" /> : reviews && <>
          <Stars rating={rating} overrideColor={overrideColor} />
          <p className={classNames("text-xs italic underline cursor-pointer", vertical ? "mt-2" : "")}>
            {longDescription ? <a href="#reviews">
                {rating} Stars from {reviews} Happy Customers
              </a> : <a href="#reviews">{reviews} Customer Reviews</a>}
          </p>
        </>}
  </div>;