"use client";

import { CreditCards } from "@/components/CreditCards";
import { ContentBlockServer } from "./ContentBlock";
import ButtonServer, { ButtonIntent, ButtonStretch } from "./ui/button";
import { StarsWithReviews } from "./Reviews/StarsWithReviews";
import Image from "@/components/Image";
import type { Payload } from "@local/payload-client/src/types";
import { classNames } from "@local/utils/src/classNames";
import { MINISTORE } from "@/lib/const";
import type { RatingScores } from "./Reviews/Stars";
import { BunnyLoader } from "@local/utils/src/bunnyLoader";
interface Props {
  block?: Payload.PromoBannerWithStarsBlock;
}
export const PromoBannerWithStarsServer = ({
  block
}: Props) => {
  if (block) {
    return <ContentBlockServer fullWidthBgColor={block.darkBackground ? "bg-gray-800" : "lg:bg-gradient-to-b lg:from-white lg:to-gray-100"} className={classNames("grid px-6 py-10 mb-4  lg:py-20 md:gap-6 md:grid-cols-2", block.darkBackground ? "text-white" : "text-gray-900")}>
        <div className="relative flex items-center justify-center w-full mx-auto mb-6 md:mb-0 min-h-[60vw] md:min-h-0">
          <Image src={block.promoImage.url} alt={block.ctaContent as string} width={block.promoImage.width} height={block.promoImage.height} loader={process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined} />
        </div>
        <div className="flex flex-col justify-center text-center md:text-left">
          {block.headline && <h2 className={classNames("mb-4 font-bold", block.subtitle ? "text-3xl" : "text-5xl")} dangerouslySetInnerHTML={{
          __html: block.headline
        }} />}
          <p className="mb-5 text-lg font-normal leading-[1.333rem]">
            {block.subtitle}
          </p>
          {block.ctaContent && <div className="w-full mx-auto md:w-1/2 md:mx-0">
              <ButtonServer content={block.ctaContent} url={block.ctaUrl} intent={ButtonIntent.CTA} stretch={ButtonStretch.full} className="mb-4" />
            </div>}
          {block.showReviewStars && <div className="mx-auto mb-5 md:mx-0 max-w-fit md:max-w-none">
              <StarsWithReviews rating={parseFloat(block.starsRating as string) as RatingScores} reviews={block.reviewsAmount} overrideColor={MINISTORE === "baerskintactical" ? "#1BB57C" : "#fbbf24"} />
            </div>}
          {block.showCreditCards && <div className="max-w-[500px] mx-auto md:mx-0 bg-white rounded-full px-2 py-1">
              <CreditCards fillHeight />
            </div>}
        </div>
      </ContentBlockServer>;
  } else {
    //TODO: add loading state
    return <>Loading...</>;
  }
};
export default PromoBannerWithStarsServer;