"use client";

import { useI10n } from "@local/i10n";
import type { Payload } from "@local/payload-client/src/types";
import Image from "@/components/Image";
import { getShippingThreshold } from "@local/cart/src/shipping";
import { BunnyLoader } from "@local/utils/src/bunnyLoader";
interface Props {
  icon: Payload.ContentIconBlock["icons"][0];
}
export const SingleIcon = ({
  icon
}: Props) => {
  const {
    currencyCode,
    currencySymbol
  } = useI10n();
  const FREE_SHIPPING_THRESHOLD = getShippingThreshold(currencyCode);
  const heading = icon.heading.replace("%%CURRENCY%%", `${currencySymbol}\u2060`).replace("%%SHIPPING%%", Math.round(FREE_SHIPPING_THRESHOLD / 100).toString());
  const text = icon.text.replace("%%CURRENCY%%", `${currencySymbol}\u2060`).replace("%%SHIPPING%%", Math.round(FREE_SHIPPING_THRESHOLD / 100).toString());
  return <li className="flex flex-col items-center justify-start text-center align-middle" data-sentry-component="SingleIcon" data-sentry-source-file="Icon.client.tsx">
      <div className="flex items-center justify-center w-4/5 aspect-square">
        <Image src={icon.image.url} alt={icon.heading} width={icon.image.width} height={icon.image.height} className="mb-2 max-w-[120px]" loader={process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined} data-sentry-element="Image" data-sentry-source-file="Icon.client.tsx" />
      </div>
      <h3 className="my-2 text-xl font-bold">{heading}</h3>
      <p>{text}</p>
    </li>;
};