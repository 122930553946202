"use client";

import { useForm } from "react-hook-form";
import { ContentBlockServer } from "../ContentBlock";
import ButtonServer, { ButtonIntent } from "../ui/button";
import { classNames, fetchApi } from "@local/utils";
import styles from "./styles.module.css";
import type { Payload } from "@local/payload-client/src/types";
import { identifyUser } from "@local/tracking/src/identify";
interface FormValues {
  terms: string;
  email?: string;
}
interface Props {
  storeName: string;
  block: Payload.MailerSignupBlock;
}
export const MailerSignupClient = ({
  storeName,
  block
}: Props) => {
  const {
    register,
    handleSubmit,
    formState: {
      isSubmitting,
      errors,
      isSubmitted
    }
  } = useForm<FormValues>();
  const submit = async ({
    email
  }: FormValues) => {
    const channel = window.channel ?? process.env.NEXT_PUBLIC_CHANNEL_ID;
    await fetchApi<{
      status: "ok";
    }>({
      endpoint: "/api/email",
      method: "POST",
      data: {
        channel,
        email
      }
    });
    if (email) {
      identifyUser({
        email
      });
    }
  };
  const title = typeof block.headline === "object" ? (block.headline as any)["en"] : block.headline;
  return <ContentBlockServer className="mx-auto mb-4" data-sentry-element="ContentBlockServer" data-sentry-component="MailerSignupClient" data-sentry-source-file="Mailer.tsx">
      <div className="flex flex-col items-center justify-center px-16 py-10 mx-4 bg-gray-100 rounded-xl lg:mx-8">
        <h2 className="w-5/6 mb-8 text-3xl font-bold text-center">{title}</h2>
        {isSubmitted ? <p>
            Thank you for registering! You&apos;ll shortly receive a
            confirmation email from us.
          </p> : <form onSubmit={handleSubmit(submit)} className="w-full text-center">
            <div className="flex flex-col items-center justify-center gap-4 mb-3 md:flex-row">
              <div className="relative w-5/6">
                <div className="absolute flex justify-center w-full -top-10">
                  {errors && (errors.email || errors.terms) && <div className={classNames("w-fit z-10 bg-red-200 py-1 px-3 rounded-md shadow-md text-Color__Text__Problem transition-all", styles.ErrorTooltip)}>
                      {errors.email?.message}
                      {errors.terms?.message}
                    </div>}
                </div>
                <input type="email" required aria-required aria-label="Email Address" {...register("email", {
              required: true
            })} placeholder="Enter your email address" className="w-full h-12 px-4 text-base text-center placeholder-gray-300 bg-white rounded-lg md:text-lg border-Color__Border__Input border-Width__Border__Input" />
              </div>
              <ButtonServer content={isSubmitting ? "Signing you up..." : "Sign Up Now"} intent={ButtonIntent.DarkCTA} className="mb-4 md:mb-0" submit disabled={isSubmitting ? true : false} />
            </div>
            <div className="flex flex-row items-center justify-center md:flex-row">
              <input aria-label="agree" aria-required type="checkbox" {...(register("terms"), {
            required: true
          })} defaultChecked={true} className="w-5 h-5 mb-2 mr-2 md:mb-0" required />
              <label htmlFor="agree">
                I agree to receive promotional marketing and emails from{" "}
                {storeName ?? "BAERSKin Tactical"}
              </label>
            </div>
          </form>}
      </div>
    </ContentBlockServer>;
};
export default MailerSignupClient;