"use client";

import { Payload } from "@local/payload-client/src/types";
import Script from "next/script";
import { useId } from "react";
interface Props {
  item: Array<Payload.FaqQuestion> | undefined;
}
export const FaqSchemaJsonLd = ({
  item
}: Props) => {
  const id = useId();
  if (!item) {
    return null;
  }
  return <Script type="application/ld+json" id={`json-ld-faq-${id}`} data-sentry-element="Script" data-sentry-component="FaqSchemaJsonLd" data-sentry-source-file="FAQ.tsx">
      {JSON.stringify({
      "@context": "https://scehma.org",
      "@type": "FAQPage",
      "mainEntity": item.map(faq => ({
        "@type": "Question",
        "name": faq.question,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": faq.answer
        }
      }))
    })}
    </Script>;
};