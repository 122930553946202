import { classNames } from "@local/utils/src/classNames";
export type RatingScores = 0 | 0.5 | 1 | 1.5 | 2 | 2.5 | 3 | 3.5 | 4 | 4.5 | 5;
export interface StarsProps {
  rating: RatingScores;
  overrideColor?: string;
  className?: string;
}
export const Star = ({
  overrideColor
}: {
  overrideColor?: string;
}) => <svg className="w-4 h-4 text-yellow-400" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-sentry-element="svg" data-sentry-component="Star" data-sentry-source-file="Stars.tsx">
    <path fill={overrideColor ?? "currentColor"} d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z" data-sentry-element="path" data-sentry-source-file="Stars.tsx"></path>
  </svg>;
export const HalfStar = ({
  overrideColor
}: {
  overrideColor?: string;
}) => {
  return <svg className={classNames("h-4 w-4 text-yellow-400", "rtl:-scale-x-100")} aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-sentry-element="svg" data-sentry-component="HalfStar" data-sentry-source-file="Stars.tsx">
      <path fill={overrideColor ?? "currentColor"} d="M288 0c-11.4 0-22.8 5.9-28.7 17.8L194 150.2 47.9 171.4c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.1 23 46 46.4 33.7L288 439.6V0z" data-sentry-element="path" data-sentry-source-file="Stars.tsx"></path>
    </svg>;
};
export const Stars = ({
  rating,
  overrideColor
}: StarsProps) => {
  const hasHalfStar = rating % 1 !== 0;
  const intRating = Math.floor(rating);
  return intRating > 0 || intRating === 0 && hasHalfStar ? <div className="flex flex-row h-4" data-sentry-component="Stars" data-sentry-source-file="Stars.tsx">
      {intRating > 0 && Array.from(Array(intRating)).map((_, i) => <Star key={i} overrideColor={overrideColor} />)}
      {hasHalfStar && <HalfStar overrideColor={overrideColor} />}
    </div> : null;
};