"use client";

import { type Payload } from "@local/payload-client/src/types";
import { ContentBlockServer } from "./ContentBlock";
const senjaId = process.env.NEXT_PUBLIC_SENJA_DATA_ID;
const SenjaReviews = ({
  block
}: {
  block: Payload.SenjaReviewsBlock;
}) => {
  if (!senjaId) return;
  return <div id="reviews" data-sentry-component="SenjaReviews" data-sentry-source-file="SenjaReviews.tsx">
      <ContentBlockServer className="py-10 mb-4 text-gray-900" data-sentry-element="ContentBlockServer" data-sentry-source-file="SenjaReviews.tsx">
        <h2 className="px-8 mb-6 text-3xl font-bold text-center lg:text-3xl">
          {block.headline.length > 1 ? block.headline : "What Our Customers Are Saying"}
        </h2>
        <div className="senja-embed" data-id={senjaId} data-lazyload="false" />
      </ContentBlockServer>
    </div>;
};
export default SenjaReviews;