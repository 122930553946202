"use client";

import type { Payload } from "@local/payload-client/src/types";
import { ContentBlockServer } from "../ContentBlock";
import { FaqItemBlockClient } from "./FaqItemBlock";
import { FaqSchemaJsonLd } from "@local/utils/src/jsonLdSchemas/FAQ";
import { useI10n } from "@local/i10n/src/useI10n";
interface Props {
  block: Payload.FaqBlock;
}
export const FaqServer = ({
  block
}: Props) => {
  const {
    isUSA
  } = useI10n();
  if (block.faq && process.env.NEXT_PUBLIC_ENABLE_KLARNA === "true" && isUSA()) {
    block.faq.push({
      question: "What is Klarna?",
      answer: '<a href="https://www.klarna.com/us/" target="_blank" rel="noreferrer noopener" aria-label="Redirect to klarna in a new tab">Klarna</a> is the smoothest & safest way to get what you want today, and pay over time.'
    });
    block.faq.push({
      question: "Questions about Klarna?",
      answer: 'Check out Klarna’s full FAQ page. You can reach Klarna anytime at <a href="https://www.klarna.com/us/customer-service/" target="_blank" rel="noreferrer noopener" aria-label="Redirect to klarna customer service in a new tab">https://www.klarna.com/us/customer-service/</a> or by downloading the Klarna app.'
    });
    block.faq.push({
      question: "Can I buy now and pay for my purchase later?",
      answer: 'Yes, you can! With Klarna payment solutions, you can choose whether you would like to pay for your order now, pay within 30 days or to pay in 4 interest free instalments. <a href="https://www.klarna.com/us/customer-service/" target="_blank" rel="noopener noreferrer" aria-label="Redirect to klarna customer service in a new tab">Contact Klarna customer support here</a>.',
      id: "klarna"
    });
  }
  return <ContentBlockServer className="px-10 mb-6" style={{
    backgroundColor: block.backgroundColor ?? "#f3f4f6",
    color: block.textColor
  }} data-sentry-element="ContentBlockServer" data-sentry-component="FaqServer" data-sentry-source-file="index.tsx">
      <div className="px-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:py-20">
        <div className="text-center">
          <div>
            <h2 className="text-3xl font-bold tracking-tight">
              {block.title ?? "Frequently asked questions"}
            </h2>
            {/* Maybe we'll need this. */}
            {/* <p className="mt-4 text-lg text-gray-500">
             Can&apos;t find the answer you&apos;re looking for? Reach out to our{" "}
             <button
              className="font-medium text-red-700 hover:text-zinc-500"
              // onClick={click}
             >
              support team
             </button>
             </p> */}
          </div>
          <div className="mt-12">
            <div className="space-y-12">
              {block.faq?.map((faq, index) => <FaqItemBlockClient data={faq} index={index} key={`${faq.question}-${index}`} />)}
            </div>
          </div>
        </div>
      </div>
      <FaqSchemaJsonLd item={block.faq} data-sentry-element="FaqSchemaJsonLd" data-sentry-source-file="index.tsx" />
    </ContentBlockServer>;
};
interface RemoteProps {
  block: Payload.RemoteFaqBlock;
  product?: Payload.Product;
}
export const RemoteFaqServer = ({
  block,
  product
}: RemoteProps) => {
  const data = {
    anchor: block.anchor,
    title: block.title || "reviews",
    description: block.description,
    faq: product?.faq.map(({
      question,
      answer,
      id
    }) => ({
      question,
      answer,
      id
    })),
    backgroundColor: block.backgroundColor,
    textColor: block.textColor,
    id: block.id,
    blockName: block.blockName,
    blockType: "faq"
  } as Payload.FaqBlock;
  return <FaqServer block={data} data-sentry-element="FaqServer" data-sentry-component="RemoteFaqServer" data-sentry-source-file="index.tsx" />;
};