"use client";

import { type CSSProperties, useState } from "react";
import Transition from "@local/ui/src/Transition";
import { ArrowDownIcon } from "../ui/icons";
import type { Payload } from "@local/payload-client/src/types";
import { useI10n } from "@local/i10n";
interface FaqItemBlockProps {
  data: Payload.FaqQuestion & {
    link?: string;
  };
  index?: number;
  style?: CSSProperties;
  isSearch?: boolean;
}
export const FaqItemBlockClient = ({
  data,
  index,
  style,
  isSearch
}: FaqItemBlockProps) => {
  const [isOpen, setIsOpen] = useState(index === 0 || isSearch ? true : false);
  const {
    currencySymbol
  } = useI10n();
  const text = typeof data.answer === "object" ? (data.answer as any)["en"]?.replace("%%CURRENCY%%", `${currencySymbol}\u2060`) : data.answer?.replace("%%CURRENCY%%", `${currencySymbol}\u2060`);
  return <dl key={data?.question} data-sentry-component="FaqItemBlockClient" data-sentry-source-file="FaqItemBlock.tsx">
      <dt className="text-lg font-medium leading-6 cursor-pointer" style={style}>
        <button className="flex flex-row items-center justify-between w-full text-left" onClick={() => setIsOpen(!isOpen)}>
          {data.question}
          {isOpen ? <div style={{
          transform: "rotate(180deg)"
        }}>
              <ArrowDownIcon />
            </div> : <ArrowDownIcon />}
        </button>
      </dt>
      <dd>
        <Transition show={isOpen} enter="transition ease-out duration-100" enterFrom="scale-95 transform opacity-0" enterTo="scale-100 transform opacity-100" leave="transition ease-out duration-100" leaveFrom="scale-100 transform opacity-100" leaveTo="scale-95 transform opacity-0" data-sentry-element="Transition" data-sentry-source-file="FaqItemBlock.tsx">
          <div className="mt-2 text-base text-left" dangerouslySetInnerHTML={{
          __html: data.link ? `<a href="${data.link}" style="text-decoration: underline; color: indigo">${text}</a>` : text
        }} style={style} />
        </Transition>
      </dd>
    </dl>;
};